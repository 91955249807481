enum TimesUnitsSelectors {
    days = '#days',
    hours = '#hours',
    minutes = '#minutes',
    seconds = '#seconds',
}

export default class Promobar {
    constructor(private promobar: HTMLElement) {
        this.promobar = promobar;

        this.init();
    }

    private init() {
        this.setPromobarCountdown();

        const promobarUrl = this.promobar.dataset.url;
        if (promobarUrl) {
            this.promobar.addEventListener('click', function(){
                window.location.href = promobarUrl;
            });
        }
    }

    private setPromobarCountdown(): void {
        const endDate = this.promobar.dataset.enddate;
        if (!endDate) {
            return;
        }

        const countDownDate = new Date(endDate).getTime();
        if (isNaN(countDownDate)) {
            return;
        }
        const promoInterval = setInterval(() => {
            const countdownContainer = this.promobar.querySelector<HTMLElement>('#discountTimer');
            if (!countdownContainer) {
                clearInterval(promoInterval);
                return;
            }

            const now      = new Date().getTime();
            const distance = countDownDate - now;

            const days    = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours   = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            this.applyCountdownValue(TimesUnitsSelectors.days, ('00' + days).slice(-2));
            this.applyCountdownValue(TimesUnitsSelectors.hours, ('00' + hours).slice(-2));
            this.applyCountdownValue(TimesUnitsSelectors.minutes, ('00' + minutes).slice(-2));
            this.applyCountdownValue(TimesUnitsSelectors.seconds, ('00' + seconds).slice(-2));

            if (countDownDate < now) {
                clearInterval(promoInterval);
                this.applyCountdownValue(TimesUnitsSelectors.days, '00');
                this.applyCountdownValue(TimesUnitsSelectors.hours, '00');
                this.applyCountdownValue(TimesUnitsSelectors.minutes, '00');
                this.applyCountdownValue(TimesUnitsSelectors.seconds, '00');
            }
        }, 1000);
    }

    private applyCountdownValue(selector: TimesUnitsSelectors, value: string) {
        const element = this.promobar.querySelector<HTMLElement>(selector);
        if (element) {
            element.innerHTML = value.toString();
        }
    }
}
